
.header
{
height:7vh; 
background-color:rgb(56, 53, 53) ;
width: 105%;  
padding-left: 2vw;
}
.fb
{
height: 60%;
margin-top: 1vh; 
margin-right: 1%;
margin-left: -80%;
}
.insta
{
    height: 65%;
    margin-top: 1vh; 
   margin-right: 1%;
}
.call
{
    height: 63%;
    margin-right:  1%;
    margin-top: 1vh;
}
.number
{
    color: white;
    margin-top: 1%;
}

@media screen and (max-width:468px) {
    .header
    {
        height: 5vh;
        padding-left: 17%;
        
    }
    .insta
    {
        height: 60%;
        margin-right: 1vw;
    }
    .call
    {
        height: 62%;
        margin-right: -1%;
    }
    .fb{
        height: 52%;
    }
    .number
    {
        font-size: 1.6vh;
        padding-top: 2%;
    }
}

@media screen and (min-width:469px) and (max-width:768px)
{
    .header
    {
        height: 6vh;
        padding-left: 12vw;
        
    }
.insta
{
    height: 50%;
    margin-right: 1vw;
}
.call
{
    height: 52%;
    margin-right: -1%;
}
.fb{
    height: 45%;
}
.number
{
    font-size: 1.7vh;
    padding-top: 2%;
}
}

@media screen and (min-width:769px) and (max-width:1024px)
{
    .header
    {
        padding-left: 10vw;  
    }

    .insta
{
    height: 50%;
    margin-right: 1vw;
}
.call
{
    height: 50%;
}
.fb{
    height: 45%;
}
.number
{
    font-size: 2vh;
    padding-top: 2%;
}

}