.rajculturepackageheading
{
    font-size:1.7rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:61%; padding-left:1%;
}
.rajculturelocation
{
    font-size:1.2rem; padding-right:2%;
}
.rajculturetouritinerary
{
     padding-top:4%; padding-right:65%; font-size: 1.7rem; font-weight:bold;
}
.rajcultureday1
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday2
{
    margin-left:-57%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday3
{
    margin-left:-42%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday4
{
    margin-left:-44%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday5
{
    margin-left:-30%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday6
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday7
{
    margin-left:-34%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday8
{
    margin-left:-57%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday9
{
    margin-left:-49%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday10
{
    margin-left:-32%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday11
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday12
{
    margin-left:-52%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday13
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday14
{
    margin-left:-47%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday15
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday16
{
    margin-left:-64%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday17
{
    margin-left:-58%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday18
{
    margin-left:-43%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday19
{
    margin-left:-76%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajcultureday20
{
    margin-left:-72%; font-weight:bold; margin-bottom: 2%;  font-size:1.3rem;
}

@media(max-width:480px)
{
    .rajculturepackageheading
    {
        font-size:1.3rem; color:rgb(49, 47, 47); margin-top :1%; padding-right:8%; margin-bottom: 5%;
    }
        .rajculturelocation
    {
        font-size:1.1rem; padding-right:1%; margin-left: 1%; 
    }
        .rajculturetouritinerary
    {
        padding-top:9%; padding-right:44%; font-size: 1.1rem; font-weight:bold;
    }
        .rajcultureday1
    {
        margin-left:-45%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday2
    {
        margin-left:-10%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday3
    {
        margin-left:-2%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday4
    {
        margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday5
    {
        margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday6
    {
        margin-left:-50%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday7
    {
        margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday8
    {
        margin-left:-19%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday9
    {
        margin-left:-2%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday10
    {
        margin-left:-2.2%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday11
    {
        margin-left:-52%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday12
    {
        margin-left:-10%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday13
    {
        margin-left:-2%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday14
    {
        margin-left:-4%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday15
    {
        margin-left:-57%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday16
    {
        margin-left:-38%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday17
    {
        margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }

    .rajcultureday18
    {
        margin-left:-2%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday19
    {
        margin-left:-60%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajcultureday20
    {
        margin-left:-45%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
}