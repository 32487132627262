.gtpuskarpackageheading
{
font-size: 1.5rem; color: rgb(49, 47, 47); margin-top : 3%; padding-right:62% ; padding-Left:1%
}
.gtpuskarloaction{
    font-size: 1.1rem; padding-right:72%; padding-left:0.5%;
}
.touritineraryhead
{
    padding-top:3%; padding-right:68%; font-size:1.6rem;font-weight:bold;
}
.gtpdayone
{
    margin-left:-70%; font-weight:bold;margin-bottom: 1%; font-size:1.2rem;
}
.gtpdaytwo
{
    margin-left:-78%; font-weight:bold;margin-bottom: 1%; font-size:1.2rem;
}

.gtpdaythree
{
    margin-left:-77%; font-weight:bold;margin-bottom: 1%; font-size:1.2rem;
}

.gtpdayfour
{
    margin-left:-65%; font-weight:bold;margin-bottom: 1%; font-size:1.2rem;
}

.gtpdayfive
{
    margin-left:-75%; font-weight:bold;margin-bottom: 1%; font-size:1.2rem;
}
.gtpdaysix
{
    margin-left:-65%; font-weight:bold;margin-bottom: 1%; font-size:1.2rem;
}
.gtpdayseven
{
    margin-left:-55%; font-weight:bold;margin-bottom: 1%; font-size:1.2rem;
}
.daycontent
{
    width:98%; padding-left:1%;
}

@media (max-width:480px)
{
    .gtpuskarpackageheading
    {
    font-size: 1.3rem; color: rgb(49, 47, 47); margin-top : 9%; padding-right:15% ; 
    }
    .gtpuskarloaction{
        font-size: 1rem; padding-right:8%; padding-left:0.5%;padding-left: 1%;
    }
    .touritineraryhead
    {
        padding-top:5%; padding-right:35%; font-size:1.2rem;font-weight:bold;
    }
        .gtpdayone
    {
        margin-left:-30%; font-weight:bold;margin-bottom: 2%; font-size:1rem;
    }
    .gtpdaytwo
{
    margin-left:-48%; font-weight:bold;margin-bottom: 2%; font-size:1rem;;
}

.gtpdaythree
{
    margin-left:-47%; font-weight:bold;margin-bottom: 2%; font-size:1rem;
}

.gtpdayfour
{
    margin-left:-20%; font-weight:bold;margin-bottom: 2%; font-size:1rem;
}

.gtpdayfive
{
    margin-left:-43%; font-weight:bold;margin-bottom: 2%; font-size:1rem;
}
.gtpdaysix
{
    margin-left:-25%; font-weight:bold;margin-bottom: 2%; font-size:1rem;
}
.gtpdayseven
{
    margin-left:-1%; font-weight:bold;margin-bottom: 2%; font-size:1rem;
}
.daycontent
{
    width:98%; padding-left:1%;
}
}