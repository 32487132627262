/* .gallery
{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count:3 ;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width:33% ;
    padding: 0 12px;
} */
 /* src/Gallery.css */
.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Space between images */
    justify-content: center;
    width: 100%;
   
  }
  
  .pics{
    flex: 1 1 200px; /* Responsive size */
    margin-top: 30px;
    margin-left: 10px;
   
  }
  
  .pics img {
    width: 100%;
    height: 270px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 250ms ease;
  }
  .pics img:hover{
    opacity: .7;
  }

  .data{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    background: black;
    transition: opacity .4s ease, visibility .4s ,transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
  }
  .data.open
  {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  .data img
  {
    width: auto;
    max-width: 100%;
    height: 600px;
    max-width: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
    overflow-x: hidden;
  }
  .data.open svg
  {
    position: fixed;
    top: 0;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: black;
    color: white;
    cursor: pointer;
  }