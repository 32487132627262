.mainbg
{
    background-image: url(../rinkuuncle/img/contactbackground.png);
    margin-bottom: 10%;
    margin-top: -10%;
    width: 100vw;
    
}
.worldtour
{
    margin-top: 15%;
    margin-left: -15%;
}

@media (max-width:480px) {

    .mainbg
    {
        margin-top: -30%;
        margin-bottom: 40%;
    }
    .contactleft
    {
       display: none;
    }
    .contactright
    {
        width: 80%;
      
    }
    
    
}
@media (min-width:481px) and (max-width:767px)
{
    .mainbg
    {
       background-image: none;
       margin-bottom: 50vw;
    }
    .contactleft
    {
       display: none;
    }
    .contactright
    {
        width: 80%;
    margin-left: 8%;
    }
    
}
@media (min-width:768px) and (max-width:991px)
{
    .mainbg
    {
        width: 100%;
    }
.worldtour
{
    height: 55%;
    margin-left: -30%;
}
}
@media (min-width:992px) and (max-width:1200px)
{
    .mainbg
    {
        width: 100%;
        height: 100%;
    }
    .worldtour
{
    height: 50%;
    margin-top: 30%;
    margin-left: -30%;
}

}