.rajpalacepackageheading{
    font-size:1.7rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:64%; padding-left:1%;
}
.rajpalacelocation
{
    font-size:1.2rem; padding-right:20%;
}
.rajpalaceitinerary
{
    padding-top:4%; padding-right:65%; font-size: 1.7rem; font-weight:bold;
}
.rajpalaceday1
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday2
{
    margin-left:-69%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday3
{
    margin-left:-78%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday4
{
    margin-left:-66%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday5
{
    margin-left:-65%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday6
{
    margin-left:-63%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday7
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday8
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday9
{
    margin-left:-42%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday10
{
    margin-left:-57%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday11
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday12
{
    margin-left:-45%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday13
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday14
{
    margin-left:-57%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday15
{
    margin-left:-65%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday16
{
    margin-left:-58%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday17
{
    margin-left:-75%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajpalaceday18
{
    margin-left:-65%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
@media(max-width:480px)
{
    .rajpalacepackageheading{
        font-size:1.3rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:10%;  margin-bottom: 5%;
    }
    .rajpalacelocation
    {
        font-size:1rem; padding-right:20%; 
    }
    .rajpalaceitinerary
    {
        padding-top:4%; padding-right:40%; font-size: 1.1rem; font-weight:bold; margin-top: 5%;
    }
    .rajpalaceday1
    {
        margin-left:-42%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday2
    {
        margin-left:-40%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday3
    {
        margin-left:-55%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday4
    {
        margin-left:-33%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday5
    {
        margin-left:-32%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday6
    {
        margin-left:-28%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday7
    {
        margin-left:-26%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday8
    {
        margin-left:-28%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday9
    {
        margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday10
    {
        margin-left:-20%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday11
    {
        margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday12
    {
        margin-left:-2%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    
    .rajpalaceday13
    {
        margin-left:-32%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday14
    {
        margin-left:-17%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday15
    {
        margin-left:-40%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday16
    {
        margin-left:-22%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday17
    {
        margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajpalaceday18
    {
        margin-left:-42%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    
}