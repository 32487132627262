.spritualpackageheading
{
    font-size:1.6rem; color:rgb(49, 47, 47); margin-top : 2%; padding-right:65%; text-transform: uppercase;
}
.sprituallocation
{
    font-size:1.1rem;padding-right:74%;padding-left:1%; 
}
.spritualitinerary
{
    padding-top:4%;padding-right:68%;font-size:1.5rem;font-weight: bold;
}
.spritualday1
{
    margin-left:-68%; font-weight:bold;margin-bottom:2%; font-size: 1.3rem;
}
.spritualday2
{
    margin-left:-63%; font-weight:bold;margin-bottom:2%; font-size: 1.3rem;
}
.spritualday3
{
    margin-left:-73%; font-weight:bold;margin-bottom:2%; font-size: 1.3rem;
}
.spritualday4
{
    margin-left:-58%; font-weight:bold;margin-bottom:2%; font-size: 1.3rem;
}
.spritualday5
{
    margin-left:-72%; font-weight:bold;margin-bottom:2%; font-size: 1.3rem;
}
.spritualday6
{
    margin-left:-58%; font-weight:bold;margin-bottom:2%; font-size: 1.3rem;
}
.spritualday7
{
    margin-left:-72%; font-weight:bold;margin-bottom:2%; font-size: 1.3rem;
}
.spritualday8
{
    margin-left:-72%; font-weight:bold;margin-bottom:2%; font-size: 1.3rem;
}
.daycontent
{
    width:98%; padding-left:1%;
}

@media(max-width:480px)
{
    .spritualpackageheading
{
    font-size:1.2rem; margin-top : 2%; padding-right:1%; text-transform: uppercase; 
}
.sprituallocation
{
    font-size:1rem;padding-right:10%;padding-left:2%; 
}
.spritualitinerary
{
    padding-top:4%;padding-right:34%;font-size:1.3rem;font-weight: bold;
}
.spritualday1
{
    margin-left:-38%; font-weight:bold;margin-bottom:2%; font-size: 1rem;
}
.spritualday2
{
    margin-left:-30%; font-weight:bold;margin-bottom:2%; font-size: 1rem;
}
.spritualday3
{
    margin-left:-49%; font-weight:bold;margin-bottom:2%; font-size: 1rem;
}
.spritualday4
{
    margin-left:-22%; font-weight:bold;margin-bottom:2%; font-size:1rem;
}
.spritualday5
{
    margin-left:-52%; font-weight:bold;margin-bottom:2%; font-size: 1rem;
}
.spritualday6
{
    margin-left:-22%; font-weight:bold;margin-bottom:2%; font-size: 1rem;
}
.spritualday7
{
    margin-left:-52%; font-weight:bold;margin-bottom:2%; font-size: 1rem;
}
.spritualday8
{
    margin-left:-52%; font-weight:bold;margin-bottom:2%; font-size: 1rem;
}
.daycontent
{
    width:98%; padding-left:1%;
}
}