.rajholidaypackageheading
{
    font-size:1.7rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:35%; padding-left:1%;
}
.rajholidaylocation
{
    font-size:1.2rem; padding-right:20%;
}
.rajholidayitinerary
{
    padding-top:4%; padding-right:65%; font-size: 1.7rem; font-weight:bold;
}
.rajholidayday1
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday2
{
    margin-left:-68%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday3
{
    margin-left:-52%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday4
{
    margin-left:-67%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday5
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday6
{
    margin-left:-63%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday7
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday8
{
    margin-left:-52%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday9
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday10
{
    margin-left:-60%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday11
{
    margin-left:-37%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday12
{
    margin-left:-59%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday13
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday14
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday15
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rajholidayday16
{
    margin-left:-65%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday17
{
    margin-left:-60%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday18
{
    margin-left:-57%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday19
{
    margin-left:-35%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rajholidayday20
{
    margin-left:-38%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
@media(max-width:480px)
{
    .rajholidaypackageheading
    {
        font-size:1.2rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:10%; padding-left:1%; padding-bottom: 4%;
    }
    .rajholidaylocation
    {
        font-size:1rem; padding-right:1%; margin-left: 1%; 
    }
    .rajholidayitinerary
    {
        padding-top:9%; padding-right:44%; font-size: 1.1rem; font-weight:bold;
    }
    .rajholidayday1
    {
        margin-left:-45%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
    }
    .rajholidayday2
{
    margin-left:-35%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday3
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday4
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday5
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday6
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday7
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday8
{
    margin-left:-5%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday9
{
    margin-left:-45%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday10
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday11
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday12
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday13
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday14
{
    margin-left:-29%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday15
{
    margin-left:-35%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rajholidayday16
{
    margin-left:-39%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday17
{
    margin-left:-24%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday18
{
    margin-left:-15%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday19
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rajholidayday20
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
}