.cookingpackageheading{
    font-size:1.8rem; color: rgb(49, 47, 47); margin-top:2%; padding-right:51%;
}
.cookinglocation
{
    font-size:1.2rem; padding-right:85% ; padding-left:1%;
}
.cookingitinerary
{
    padding-top:3%; padding-right:65%; font-size: 1.7rem ; font-weight:bold;
}
@media (max-width:480px)
{
    .cookingpackageheading{
        font-size:1.3rem; color: rgb(49, 47, 47); margin-top:2%; padding-right:31%;
    }
    .cookinglocation
    {
        font-size:1.1rem; padding-right:55% ; padding-left:1%;
    }
    .cookingitinerary
    {
        padding-top:3%; padding-right:34%; font-size: 1.2rem ; font-weight:bold;
    }
}