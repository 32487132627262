.spritualsamepackageheading
{
    font-size: 4.5vh; color: rgb(49, 47, 47); margin-top :2%; padding-right:75%;
}
.spritualsamelocation
{
    font-size: 3vh;padding-right:86%; padding-left: 0.7%;
}
.spritualsameitinerary
{
    padding-top:5%; padding-right:64%; font-size:4.5vh; font-weight: 600;
}
.spritualsameday1
{
    margin-left:-58%; font-weight:bold; margin-bottom: 5vh;
}

@media (max-width:480px)
{
    .spritualsamepackageheading
    {
        font-size: 1.2rem;
        padding-right: 38%;
        margin-top: 2%;
        font-weight: bold;
    }
    .spritualsamelocation
    {
        font-size:1rem;padding-right:60%; padding-left: 1%;
    }
    .contentbox
{
     border: 0.1vw solid red;margin-left: 0.3%; border-radius: 9px; padding-top: 3% ;text-transform: capitalize; width: 100%;
}
    .spritualsameitinerary
    {
        padding-top:5%; padding-right:35%; font-size:1.2rem; font-weight:bold;
    }
    .spritualsameday1
    {
        margin-left:-25%;  margin-bottom: 1.5vh; font-size:1rem;
    }
}