    .row
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .first_img_popular
    {
        height: 25vh; 
        width: 100%;
        margin-top: 2vh;
       
    }
    .popular_head
    {
        font-family: serif; 
        text-align: center;
         text-transform: capitalize; 
         font-size: 2.5vw; 
         padding-bottom: 9vh;
         padding-top: 2vh;
         /* margin-left: 20%; */
         
    }
    .div_head
    {
        text-transform: uppercase;
        font-weight: bold; 
        margin-top: 3vh; 
        margin-left: 5px;
        
    }
    .one_row_popular
    {
        transition: transform.3s;
        -webkit-box-shadow: 0px 0px 17px 0px rgba(184,182,184,1);
                -moz-box-shadow: 0px 0px 17px 0px rgba(184,182,184,1);
                box-shadow: 0px 0px 17px 0px rgba(184,182,184,1);
                 width: 20%; 
                 height: 60vh;
                 margin-bottom: 3vh;
                 margin-left: -2%;
                 margin-right: 5%;

    }
    .cardlink
    {
        text-decoration: none;
        color: black;
    }
    .one_row_popular:hover
    {
        transform: scale(1.1);
    }
    .popular_btn
    {
        background-color: rgb(248, 243, 170);
        border: 2px solid #ff8547 ;
        margin-top: 2vh;
        color: red;
        width: 10vw;
        border-radius:2vh;
        height: 5vh;
    }
   
    .popular_content
    {
        margin-top: 2vh;
    }

    
@media screen and (max-width:480px)
{
    .row
    {
        flex-direction: column;
    }

    .popular_head
    {
        font-size: 2rem;
        margin-bottom: -10%;
    
    }

    .one_row_popular
    {
        width: 85%;
        height: 35%;
        margin-left: -12%;
    }

    .first_img_popular
    {
        width: 100%;
        height: 10rem;
    }
    .popular_content
    {
      font-size: 1rem;
    }

    
}
@media  (min-width: 481px) and (max-width:767px)
{
    .popular_head
    {
        font-size: 2.5rem;
        margin-top: -40%;
        margin-bottom: -2%;
    }
}
@media  (min-width: 768px) and (max-width:991px)
{
   
    .popular_head
    {
        font-size: 2rem;
        margin-top: 5%;
        margin-bottom: -2%;
    }
}
@media  (min-width: 992px) and (max-width:1200px)
{
   
    .popular_head
    {
        font-size: 2.5rem;
        margin-top: 15%;
    }
}
@media  (min-width: 1201px) 
{
   
    .popular_head
    {
        font-size: 2.5rem;
        margin-top: -1%;
    }
}
