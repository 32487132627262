.romanticpackageheading
{
    font-size:1.7rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:60%; padding-left:1%;
}
.romanticlocation
{
    font-size:1.2rem; padding-right:24%; margin-left: 1%;
}
.romanticitinerary
{
    padding-top:4%; padding-right:65%; font-size: 1.7rem; font-weight:bold;
}
.romanticday1
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday2
{
    margin-left:-58%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday3
{
    margin-left:-52%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday4
{
    margin-left:-56%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday5
{
    margin-left:-37%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday6
{
    margin-left:-57%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday7
{
    margin-left:-42%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday8
{
    margin-left:-45%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday9
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday10
{
    margin-left:-48%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday11
{
    margin-left:-59%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday12
{
    margin-left:-65%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday13
{
    margin-left:-42%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday14
{
    margin-left:-16%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.romanticday15
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
@media(max-width:480px)
{
    .romanticpackageheading
{
    font-size:1.2rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:9%; padding-left:1%; margin-bottom: 5%;
}
.romanticlocation
{
    font-size:0.9rem; padding-right:18%;
}
.romanticitinerary
{
    padding-top:4%; padding-right:44%; font-size: 1.1rem; font-weight:bold;
}
.romanticday1
{
    margin-left:-44%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday2
{
    margin-left:-10%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday3
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday4
{
    margin-left:-10%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday5
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday6
{
    margin-left:-14%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday7
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday8
{
    margin-left:-2%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday9
{
    margin-left:-24%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday10
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday11
{
    margin-left:-19%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday12
{
    margin-left:-29%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday13
{
    margin-left:-3%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday14
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.romanticday15
{
    margin-left:-24%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
}