
.main_content
{

    margin-left: 12%;
}

.contactus_image{
    width: 100%;
    height: 60vh;
    margin-top: 0.2%;
}
.contact_heading
{
    text-align: center;
    text-transform: uppercase;
    font-family: serif;
    font-size: 2.8rem;
    font-weight: 530;
    margin-top: 5%;
}
#office_heading
{
    text-transform: uppercase;
    margin-top: -2%;
    margin-left: 5%;
    text-decoration: underline;
    font-size: 1.9rem;
    font-family: serif;
    font-weight: 500;
}
.address_detail
{
    font-size: 1.3rem;
    font-family: serif;
    text-transform: capitalize;
}
.mobile_detail
{
    font-size: 1.3rem;
    font-family: serif;
    text-transform: capitalize;
}
.email_detail
{
    font-size: 1.3rem;
    font-family: serif;
    text-decoration: none;
}
#office_timing_heading
{
    text-transform: uppercase;
    margin-top: -2%;
    margin-left: -75%;
    text-decoration: underline;
    font-size: 1.9rem;
    font-family: serif;
    font-weight: 500;
}
.office_time
{
    text-transform: capitalize;
    font-size: 1.7rem; margin-left: -72%;
    margin-top: 2%;
}
.facility
{
    text-transform: capitalize;
    font-size: 1.7rem;
     margin-left: -72%;
     margin-top: -13px;
}
.contact_background
{
/* background-color: rgb(255, 255, 255);     */
/* height: 100%;
background-image:url("../rinkuuncle/img/contactbackground.png"); */

}
.contact_form_head
{

    text-align: center;
    text-transform: uppercase;
    font-family: serif;
    font-size: 2.5rem;
    font-weight: 530;
    margin-top: 5%;
    margin-bottom: 8%;

}

.uppersleepingline
{
border-top: 2px solid black;
 width:28% ; 
 margin-left:36%;
 margin-bottom: 10vh;
}

.sleepingline
{
border-top: 2px solid black;
 width:28% ; 
 margin-left:36%;
 margin-bottom: 10vh;
}
.standingline
{
    border-left: 2px dotted black; height: 40vh; margin-top:5%; margin-left:10%; 
}

@media (max-width:480px)

{
    .contactus_image
    {
        height: 40vh;
        margin-top: 3%;
    }
    .contact_heading
    {
        margin-top: 10%;
        font-size: 1.5rem;
        
    }
    
    .main_content
    {
        margin-left: -2%;
        margin-top: 5%;
    }
    #office_heading
    {
        font-size: 1.3rem;
        margin-left: 1%;
        margin-top: -5%;
    }
    .h4
    {
        font-size: 1.2rem;
        margin-top: -5%;
    }
    .address_detail
{
    font-size: 1.1rem;
   
}
.mobile_detail
{
    font-size: 1.1rem;
  
}

    .email_detail
    {
        font-size: 1.1rem;
    }
    .standingline
    {
        display: none;
    }
    #office_timing_heading
    {
        margin-left: 2%;
        margin-top: 10%;
        font-size: 1.3rem;
    }
    .office_time
    {
        margin-left: 2%;
        margin-top: -5%;
        font-size: 1.1rem;
    }
    .facility
    {
        margin-left: 2%;
        font-size: 1.1rem;
    }
    .contact_form_head
    {
        font-size: 1.7rem;
        margin-bottom: 1%;
    }

}

@media(min-width:481px)and (max-width:767px)
{

    .contactus_image
    {
        height: 40vh;
        margin-top: 3%;
    }
    .contact_heading
    {
        margin-top: 10%;
        font-size: 2.5rem;
        
    }
    
    .main_content
    {
        
        margin-top: 5%;
    }
    #office_heading
    {
        font-size: 1.9rem;
        margin-left: 1%;
        margin-top: -5%;
    }
    .h4
    {
        font-size: 1.8rem;
    }
    .address_detail
{
    font-size: 1.5rem;
   
}
.mobile_detail
{
    font-size: 1.5rem;
  
}

    .email_detail
    {
        font-size: 1.5rem;
    }
    .standingline
    {
        display: none;
    }
    #office_timing_heading
    {
        margin-left: 2%;
        margin-top: 10%;
        font-size: 1.9rem;
    }
    
    .office_time
    {
        margin-left: 2%;
        margin-top: -5%;
        font-size: 1.5rem;
    }
    .facility
    {
        margin-left: 2%;
        font-size: 1.5rem;
    }
    .contact_form_head
    {
        font-size: 2.5rem;
        margin-bottom: 1%;
    }

}

@media screen and (min-width:768px) and (max-width:988px) {
    .contactus_image
    {
        height: 50vh;
    }
    .contact_heading
    {
        margin-top: 7%;
        font-size: 2rem;
        margin-left: 5%;
    }
    #office_heading
    {
        font-size: 1.5rem;
    }
    .h4
    {
        font-size: 1.3rem;
    }
    #office_timing_heading
    {
        font-size: 1.5rem;
        margin-left: -12%;
    }
    .main_content
    {
        margin-top: -15%;
        margin-left: 10%;
    }
    .address_detail
    {
        font-size: 1rem;
        margin-left: -5%;
    }
    .mobile_detail
    {
        font-size: 1rem;
        margin-left: -10%;
    }
    .email_detail
    {
        font-size: 1rem;
        margin-left: -10%;
    }
    .office_time
    {
        font-size: 1.3rem;
        margin-left: -10%;
    }
    .facility
    {
        font-size: 1.3rem;
        margin-left: -10%;
    }
    .standingline
    {
        margin-left: 50%;
        height: 100%;
    }
    .uppersleepingline
    {
        margin-left: 40%;
        margin-bottom: 20%;
    }
    .contact_form_head
    {
        font-size: 2rem;
        margin-top: 10%;
        margin-bottom: 1%;
    }
}