.rctpackageheading
{
    font-size:1.7rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:60%; padding-left:1%;
}
.rctlocation
{
    font-size:1.2rem; padding-right:57%;
}
.rcttouritinerary
{
    padding-top:4%; padding-right:65%; font-size: 1.7rem; font-weight:bold;
}
.rctdayone
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.rctdaytwo
{
    margin-left:-67%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdaythree
{
    margin-left:-70%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdayfour
{
    margin-left:-20%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdayfive
{
    margin-left:-75%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdaysix
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdayseven
{
    margin-left:-62%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdayeight
{
    margin-left:-35%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdaynine
{
    margin-left:-73%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdayten
{
    margin-left:-45%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdayeleven
{
    margin-left:-49%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdaytweleve
{
    margin-left:-72%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

.rctdaythirteen
{
    margin-left:-35%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

@media(max-width:480px)
{
    .rctpackageheading
{
    font-size:1.2rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:10%; padding-left:1%;
}
.rctlocation
{
    font-size:1rem; padding-right:47%;
}
.rcttouritinerary
{
    padding-top:4%; padding-right:44%; font-size: 1.1rem; font-weight:bold;
}
.rctdayone
{
    margin-left:-44%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
.rctdaytwo
{
    margin-left:-32%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdaythree
{
    margin-left:-40%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdayfour
{
    margin-left:0%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdayfive
{
    margin-left:-59%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdaysix
{
    margin-left:-30%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdayseven
{
    margin-left:-25%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdayeight
{
    margin-left:-2%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdaynine
{
    margin-left:-54%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdayten
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:0.9rem;
}

.rctdayeleven
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdaytweleve
{
    margin-left:-55%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}

.rctdaythirteen
{
    margin-left:-1%; font-weight:bold; margin-bottom: 1%;  font-size:1rem;
}
}