.agrapackageheading
{
    font-size: 5vh; color: rgb(49, 47, 47); padding-right:77%;
}
.agralocation
{
    font-size: large; padding-right:90%;padding-left:0.5%;
}
.agraitinerary
{
    padding-top:3%; padding-right:70%;font-size:4vh;font-weight: "600";
}
.agraday1
{
    margin-left:-23%; font-weight:bold; margin-bottom:30px;
}
@media (max-width:480px)
{
    .agrapackageheading
    {
        font-size:1.1rem;
        padding-right: 55%;
        padding-left: 2%;
        font-weight: bold;
    }
    .agralocation
{
    font-size: large; padding-right:75%;padding-left:3px;
}
.d-block
{
    height: 250px;
}
.agraitinerary
{
    padding-top:4%; padding-right:45%;font-size:1.1rem;font-weight: bold; width: 100%; text-align: left;
}
.contentbox
{
    width: 90%;
   margin-left: -1%;
}
.agraday1
{
    margin-left:-0.1rem; font-weight:bold; margin-bottom:4%;font-size: 1rem; 
}

}
@media (min-width:481px) and (max-width:767px)
{
    .agrapackageheading
    {
        font-size:1.8rem;
        padding-right: 48%;
        padding-left: 2%;
        font-weight: bold;
        text-align: left;
    }
    .agralocation
    {
        font-size:1.2rem; padding-right:83%;padding-left:3px; text-align: left;
    }
    .agraitinerary
    {
        padding-top:4%; padding-right:50%;font-size:1.4rem;font-weight: bold; text-align: left; 
    }
    .contentbox
    {
    width: 97%;
    margin-left: -1%;
    }
    .agraday1
    {
        margin-left:1rem; font-weight:bold; margin-bottom:4%;font-size: 1.2rem; text-align: left;
    }
}

@media (min-width:768px) and (max-width:991px)
{
    .agrapackageheading
    {
        padding-right: 45%;
        font-size: 2rem;
        text-align: left;
        margin-left: 2%;
    }
    .d-block
    {
        height: 380px;
    }
    .agraitinerary
    {
        padding-top:4%; padding-right:40%;font-size:1.5rem; text-align: left;  margin-left: 4%;
    }
    .contentbox
    {
    width: 97%;
    margin-left: -1%;
    }
    .agraday1
    {
        margin-left:1rem; font-weight:bold; margin-bottom:4%;font-size: 1.2rem; text-align: left;
    }
}