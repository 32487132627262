
.package
{
    width:120%;
    height: 80%;
    margin-left: -14%;
    border-radius: 5%;
    -webkit-box-shadow:0px 0px 17px 0px rgba(184,182,184,1);
    -moz-box-shadow:0px 0px 17px 0px rgba(184,182,184,1);
    margin-bottom: 25%;
    transition: transform.3s;
}
.packagelink
{
    text-decoration: none;
    color: black;
}
.package:hover
{
    transform: scale(1.1);
}
.packageimg
{
    width: 95%;
    margin-top: 2%;
    border-radius: 5%;
    height: 45%;
}
.packagehead
{
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 4%;
}
@media  (max-width:480px) {
    .package
    {
        width:80%;
        height: 100%;
        margin-left: 11%;
        margin-bottom: 10%;
    }
    .packageimg
    {
        width:90%;
        height: 35vw;
    }
    .packagehead
    {
        font-size: 1rem;
    }
    .package p
    {
        font-size: 0.9rem;
        padding: 1px;
    }
    .package:hover
    {
        transform: scale(1.0);
    }

}
@media (min-width:481px) and (max-width:767px)
{
    .package
    {
        width:70%;
        height: 90%;
        margin-bottom: 10%;
        margin-left: 14%;
    }
}
@media (min-width:768px) and (max-width:991px) {

    .package
{
    width:130%;
    height: 95%;
    margin-left: -15%;
    margin-bottom: 50%;

}
.package:hover
{
    transform: scale(1.0);
}
.packagehead
{
    font-size: 1rem;
    margin-top: 4%;
}
.package p
{
    font-size: 0.9rem;
    padding: 0 1vw;
}
}

@media (min-width:992px) and (max-width:1200px)
{
    .package
    {
        width: 130%;
        height: 90%;
    }
    .package:hover
    {
        transform: scale(1.0);
    }
    .packagehead
{
    font-size: 1.3rem;
    margin-top: 4%;
}.package p
{
    font-size: 1.1rem;
    padding: 0 1vw;
}
}