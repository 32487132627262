*
{
    padding: 0;
    margin: 0;
    font-family: serif;
}

.outer-div
{
    background: rgb(255, 255, 255);
    width: 100%;
    height: 9vh;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1000;
}
   

.logo
{
    height:3.5vw; 
    width: 13vw;
    margin-top:1%;
    margin-left: 5%;
    
}
nav
{
   margin-right: 100px;
   
   
}

nav a{
    color: black;
    font-size: 20px;
}
nav ul 
{
    list-style-type: none;
    margin: 0;
    
}

nav ul li{
    display: inline-block;
    /* padding: 18px 25px; */
   
    text-transform: capitalize;
    border-radius: 10px;
    transition: all 0.3s ease;
    margin-top: 10px;
  
    padding: 12px 8px;
    
}

nav ul li a{
    text-decoration: none;
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    padding-top: 45px;
    padding-bottom: 45px;
    padding-right: 10px;
    padding-left: 10px;
}
nav ul li:hover
{
    background: rgb(255, 251, 4);
}
.dropdown{display: none;}
ul li:hover .dropdown
{
display: block;
position: absolute;
margin-top: 15px;
margin-left: -10px;
background: rgb(255, 255, 255);
z-index: 990;


}

.dropdown ul{
 margin-top: 0px;
 margin-left: 0%;
padding: 0;
 border: 3PX solid yellow;
 border-radius: 10px;
 height: 530px;
 width: 100%;
 
}
.dropdown ul li{
display:block;
margin-top: -27px;
position: relative;
padding: 9px 8px;
text-align: left;
font-size: 15px;
}

.ham-menu
{
  display: none;
  margin-right: 5%;
  font-size: 2rem;
  
}
.menubtn
{
    border: none;
    background: none;
}
@media (max-width:965px){
   .logo{
    height: 75%;
    width: 70%;
    margin-left: -30%;
   }
    .ham-menu
   {
    display: block;
    cursor: pointer;
    padding-bottom: 5rem    ;
   }
   .menu-web
   {
    display: none;
   }
   .menu-mobile ul
   {
    display: flex;
    flex-direction: column;

    & li:first-child
    {
        margin-top: 1.5rem;
    }
    & li:last-child
    {
        margin-bottom: 1.5rem;
    }
   }
   .menu-mobile
   {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    width: 100%;
    padding-right:2% ;
    box-shadow: rgb(20, 20, 20) 0px 14px 20px;
    animation: slideDown 2s ease-out forwards;
   }
   
    ul li:hover .dropdown
    {
    display:none;
    }
    .logo
    {
        margin-top: 4%;
    }
}