.slick-slide > div{
    margin: 30px 20px;
}
.reviewsectiondiv
{
    height:40vw; margin-bottom:5%;
    margin-top: -5%; width: 100%;
}
.reviewhead
{
    height:7vw;
}
.card
{
    height: 100%;
    background-color: rgb(125, 224, 121);
    width: 5vw;
    color: rgb(0, 0, 0);
    border-radius: 8px;
}
.cardimg
{
    height: 14vw;
    border-radius: 5vw;
    justify-content: center;
    margin-top: -1vh;
}
.img
{
    height: 11vw;
    width: 40%;
    border-radius: 50%;
    margin-top: 4%;
    margin-left: 30%;
}

.cardcontent
{
   
    flex-direction: column;
    justify-items: center;
    gap: 4;
    margin-top: -1vw;
}
.clientname
{
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.8rem;
}
.review
{
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.3rem;
}

@media(max-width:480px)
{
    .reviewsectiondiv
{
    height:100%; margin-bottom:30%;
    margin-top: -30%; width: 100%;
}
.reviewhead
{
    height:18vw;
}
.card
{
    height: 100%;
    background-color: rgb(191, 252, 142);
    width: 100%;
    color: black;
    border-radius: 5vw;
    margin-top: -2.5vw;
}
.img
{
    height: 30vw;
    width: 38%;
    border-radius: 50%;
    margin-top: 5%;
    margin-left: 30%;
}

.review
{
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1rem;
    
}
.clientname
{
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.6rem;
    margin-top: 25%;
    margin-left: -2%;
}
}
@media(min-width:481px) and (max-width:767px)
{
    .reviewsectiondiv
    {
        height:100%; margin-bottom:10%;
        margin-top: -40%; width: 100%;
    }
    .reviewhead
    {
        height:13vw;
    }
    .card
    {
        height: 100%;
        background-color: rgb(255, 255, 255);
        width: 100%;
        color: black;
        border-radius: 5vw;
        margin-top: -2.5vw;
    }
    .img
    {
        height: 19vw;
        width: 58%;
        border-radius: 50%;
        margin-top: 15%;
        margin-left: 20%;
    }
    
    .review
    {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 0.9rem;
        
    }
    .clientname
    {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 1.5rem;
        margin-top: 25%;
        margin-left: -2%;
    }
}
@media(min-width:768px) and (max-width:991px)
{
        .reviewsectiondiv
    {
        height:60vw; margin-bottom:1%;
        margin-top: -5%; width: 100%;
    }
    .reviewhead
    {
        height:9vw;
    }
    .card
    {
        height: 100%;
        background-color: rgb(182, 226, 141);
        width: 100%;
        color: black;
        border-radius: 5vw;
        margin-top: -2.5vw;
    }
    .img
    {
        height: 14vw;
        width: 52%;
        border-radius: 50%;
        margin-top: 10%;
        margin-left: 23%;
    }
    
    .review
    {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 0.9rem;
        margin-top: -5%;
    }
    .clientname
    {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 1.3rem;
        margin-top: 5%;
        
    }
}
@media(min-width:992px) and (max-width:1200px)
{
    .card
    {
        background-color: rgb(182, 226, 141);
    }
}
