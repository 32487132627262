.junglepackageheading
{
    font-size:1.7rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:65%; padding-left:1%;
}
.junglelocation
{
    font-size:1.2rem; padding-right:54%; 
}
.jungletouritinerary
{
    padding-top:4%; padding-right:67%; font-size: 1.7rem; font-weight:bold;
}
.jaipurdayone
{
    margin-left:-60%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdaytwo
{
    margin-left:-73%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdaythree
{
    margin-left:-72%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdayfour
{
    margin-left:-79%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdayfive
{
    margin-left:-79%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdaysix
{
    margin-left:-79%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdayseven
{
    margin-left:-79%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdayeight
{
    margin-left:-71%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdaynine
{
    margin-left:-70%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdayten
{
    margin-left:-76%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdayeleven
{
    margin-left:-76%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}
.jaipurdaytweleve
{
    margin-left:-75%; font-weight:bold; margin-bottom:1%;  font-size:1.2rem;
}

@media (max-width:480px)
{
    .junglepackageheading
    {
        font-size:1.2rem; color:rgb(49, 47, 47); margin-top :2%; padding-right:5%; 
    }
    .junglelocation
    {
        font-size:0.9rem; padding-right:1%; padding-left: 1%;
    }
    .jungletouritinerary
    {
       padding-top:4%; padding-right:38%; font-size: 1.1rem; font-weight:bold;
    }
    .jaipurdayone
    {
        margin-left:-15%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
        .jaipurdaytwo
    {
        margin-left:-42%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdaythree
    {
        margin-left:-40%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdayfour
    {
        margin-left:-56%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdayfive
    {
        margin-left:-55%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdaysix
    {
        margin-left:-58%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdayseven
    {
        margin-left:-57%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdayeight
    {
        margin-left:-42%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdaynine
    {
        margin-left:-44%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdayten
    {
        margin-left:-57%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdayeleven
    {
        margin-left:-57%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }
    .jaipurdaytweleve
    {
        margin-left:-55%; font-weight:bold; margin-bottom:1%;  font-size:0.9rem;
    }

}