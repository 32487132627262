.privacy
{
width:90%; margin-Left:5%; border:1px solid black;  margin-top:6%; padding-top:5%;
}
@media(max-width:480px)
{
    .privacy{
        width: 100%;
        margin-left: -0.1%;
    }
}