.gtheading
{
    font-size: 4vh; color: rgb(49, 47, 47); margin-top : 4%; padding-right:60% ; padding-left:1%;
}
.gtlocation
{
    font-size:2.8vh; padding-right:63%;padding-left:1% ;
}
.horizontalline
{
     border-top: 0.1vw solid blue; margin-bottom: 3vh ; margin-top:2%;
}
.image_div
{
    margin-top: 5%; margin-left:5%;
}
.touritineraryheading
{
     padding-top:4%; padding-right:65%;font-size: 4vh;font-weight: 500
}
.contentbox
{
     border: 0.1vw solid red;margin-left: 2%; border-radius: 9px; padding-top: 2% ;text-transform: capitalize;
}
.dayone
{
    margin-left:-25%; font-weight:bold; margin-bottom: 2%;  font-size:3.1vh;
}
.dayonecontent
{
    width:98%; padding-left:1%;
}
.daytwo
{
   margin-top:0.5%; font-weight:bold; margin-bottom: 1%;  font-size:3.1vh; margin-left: -83%;
}
.daytwocontent
{
    width:98%;padding-left:1%;
}
.daythree
{
    margin-top:0.5%; font-weight:bold; margin-bottom: 1%;  font-size:3.1vh; margin-left: -83%;
}
.daythreecontent
{
    width:98%;padding-left:1%;
}
.dayfour
{
    margin-top:0.5%; font-weight:bold; margin-bottom: 1%;  font-size:3.1vh; margin-left: -83%;
}
.dayfourcontent
{
    width:98%;padding-left:1%;
}
.dayfive
{
    margin-top:0.5%; font-weight:bold; margin-bottom: 1%;  font-size:3.1vh; margin-left: -83%;
}
.dayfiveecontent
{
    width:98%;padding-left:1%;
}
@media(max-width:480px)
{
    .gtheading
{
    font-size: 1.2rem; color: rgb(49, 47, 47); margin-top : 15%; padding-right:20% ;
}
.gtlocation
{
    font-size:0.9rem; padding-right:69% ;padding-left: 2%; 
}

.touritineraryheading
{
     padding-top:10%; padding-right:34%;font-size:1.3rem;font-weight:bold
}
.contentbox
{
     border: 0.1vw solid red;margin-left: 1%; border-radius: 9px; padding-top: 3% ;text-transform: capitalize; width: 100%;
}
.dayone
{
    margin-left:-1%; font-weight:bold; margin-bottom: 2%;  font-size:1rem;
}
.dayonecontent
{
    width:98%; padding-left:2%; padding-top: 2%;
}
.daytwo
{
   margin-top:0.5%; font-weight:bold; margin-bottom: 1%;  font-size:1rem; margin-left: -70%;
}
.daytwocontent
{
    width:98%; padding-left:2%; padding-top: 2%;
}
.daythree
{
    margin-top:0.5%; font-weight:bold; margin-bottom: 1%;  font-size:1rem; margin-left: -70%;
}
.daythreecontent
{
    width:98%; padding-left:2%; padding-top: 2%;
}
.dayfour
{
    margin-top:0.5%; font-weight:bold; margin-bottom: 1%;  font-size:1rem; margin-left: -70%;
}
.dayfourcontent
{
    width:98%; padding-left:2%; padding-top: 2%;
}
.dayfive
{
    margin-top:0.5%; font-weight:bold; margin-bottom: 1%;  font-size:1rem; margin-left: -70%;
}
.dayfiveecontent
{
    width:98%; padding-left:2%; padding-top: 2%;
}
}