
.gtwithtigerpackageheading
{
font-size: 1.6rem; color: rgb(49, 47, 47); margin-top : 2%; padding-right:48% ; padding-left:1%;
}
.gttigerlocation
{
    font-size: 1.1rem; padding-right:55%; padding-left:1%;
}
.gttigertouritinerary
{
    padding-top:4%; padding-right:66%; font-size:1.7rem; font-weight: bold;
}
.gttigerdayone
{
    margin-left:-85%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.gttigerdaytwo
{
    margin-left:-85%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.gttigerdaythree
{
    margin-left:-85%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.gttigerdayfour
{
    margin-left:-85%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}
.gttigerdayfive
{
    margin-left:-720px; font-weight:bold; margin-bottom: 30px;  font-size:20PX;
}
.gttigerdaysix
{
    margin-left:-85%; font-weight:bold; margin-bottom: 1%;  font-size:1.3rem;
}

@media (max-width:480px) {
    .gtwithtigerpackageheading
    {
    font-size: 1.2rem; margin-top : 1%; padding-right: 10% ; 
    }
    .gttigerlocation
    {
        font-size: 1rem; padding-right:20%; padding-left:1%;
    }
    .gttigertouritinerary
    {
        padding-top:6%; padding-right:36%; font-size:1.2rem; font-weight: bold;
    }
    .gttigerdayone
    {
        margin-left:-75%; font-weight:bold; margin-bottom: 1%;  font-size:1.1rem; margin-top: 1%;
    }
    .gttigerdaytwo
    {
        margin-left:-75%; font-weight:bold; margin-bottom: 1%;  font-size:1.1rem; margin-top: 1%;
    }
    .gttigerdaythree
    {
        margin-left:-75%; font-weight:bold; margin-bottom: 1%;  font-size:1.1rem; margin-top: 1%;
    }
    .gttigerdayfour
    {
        margin-left:-75%; font-weight:bold; margin-bottom: 1%;  font-size:1.1rem; margin-top: 1%;
    }
    .gttigerdayfive
    {
        margin-left:-75%; font-weight:bold; margin-bottom: 1%;  font-size:1.1rem; margin-top: 1%;
    }
    .gttigerdaysix
    {
        margin-left:-75%; font-weight:bold; margin-bottom: 1%;  font-size:1.1rem; margin-top: 1%;
    }
    .contentbox
    {
        width: 90%;
    margin-left: -1%;
    }
}