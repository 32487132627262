
.rainbow_travel_container
        {
            border: 1px solid black;
            height: 100%;
            width: 100%;
            border-top: none;
            padding-top: 4%;
            margin-top: 3%;

        }
        .main_head
        {
            text-align: center;
            font-size: 7vh;
            font-weight: 600;
            font-family: serif;
            margin-top: -2%;
        }
        .guest
        {
            text-align: center;
            font-size: 4vh;
            font-weight: 500;
            font-family: serif;
            margin-top: 4%;
        }
        .contactusimg
        {
            width:100%; height:60vh
        }

        @media (max-width:600px)
        {
            .contactusimg
            {
                height: 35vh;
            }
            .main_head
            {
               font-size: 4vh;
            }
            .guest
            {
                font-size: 3vh;
            }

        }

       @media (min-width:601px) and (max-width:786px) {
        .contactusimg
        {
            height: 42vh;
        }
        .main_head
        {
            font-size: 5vh;
        }
        .guest
        {
            font-size: 3.5vh;
        }

       }

       @media (min-width:787px) and (max-width:1080px)
       {
        .contactusimg
        {
            height: 50vh;
        }
        .main_head
        {
            font-size: 5vh;
        }
        .guest
        {
            font-size: 3.5vh;
        }
       }