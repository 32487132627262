.jaipurpackageheading
{
    font-size:1.8rem; color: rgb(49, 47, 47); margin-top:2%; padding-right:78%;
}
.jaipurlocation
{
    font-size:1.2rem; padding-right:89% ; padding-left:1%;
}
.jaipurtouritinerary
{
    padding-top:3%; padding-right:65%; font-size: 1.7rem ; font-weight:bold;
}
.Jaipurdayone{
    font-size: 1.2rem; margin-top: -1%; margin-bottom: 1%; font-weight: bold; margin-left: -57%;
}

@media (max-width:480px)
{
    .jaipurpackageheading
{
    font-size:1.3rem; color: rgb(49, 47, 47); margin-top:2%; padding-right:43%;
}
.jaipurlocation
{
    font-size:1rem; padding-right:68% ; padding-left:2%;
}
.jaipurtouritinerary
{
    padding-top:5%; padding-right:33%; font-size: 1.3rem; font-weight:bold;
}
.Jaipurdayone
{
    margin-left:-1%; font-weight:bold; margin-bottom: 2%; font-size: 1rem; margin-top: 1%;
}
}