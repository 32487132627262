.ourpackagesimg
{
width:100%;
height:60vh;
margin-bottom: 5%;
}
@media (max-width:500px)
{
.ourpackagesimg
{
    height: 45vh;
}
}
@media (min-width:501px) and (max-width:768px) {
    .ourpackagesimg
{
    height: 47vh;
}  
}

@media (min-width:769px) and (max-width:1080px) {
    .ourpackagesimg
{
    height: 50vh;
}
}