.enquiry_form
{
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgb(248, 235, 44);
    height: 110vh;
    padding-top: 4vh;
    background-color:    #b299b2;
    width: 100%;
}
.enquiry_form input{
    
    width:75%;
    height: 6%;
    border-radius: 2vh;
    padding-left: 3%;
    font-size: 2.7vh;
    text-transform: capitalize;
    border-color:orange;
    margin-bottom: 4%;
    margin-left: 11%;
}
.heading
{
    color: yellow;
    font-family: serif;
    font-weight: 600;
    font-size: 3.3vh;
    padding-left: 3%;
    padding-bottom: 26px;

}
.message
{
    
    width:75%;
    border-radius: 2vh;
    padding-left: 3%;
    font-size: 3vh;
    text-transform: capitalize;
    border-color:orange;
    margin-bottom: 4%;
    margin-left: 11%;
}
.button
{
   
    width:40%;
    height: 6%;
    border-radius: 3vh;
    font-size: 3vh;
    margin-left: 30%;
    background: crimson;
    color: white;
    border-color:orange;
    margin-bottom: 4%;
}
/* box */
.enquirybox
{
    border: 1px solid red;
    margin-left: -40%;
    margin-top: 40%;
    width: 120%;
    height: 40vh;
    padding-top: 10%;
    border-radius: 15px;
}
.contactheading
{
    font-size: 3rem;
    color: rgb(238, 74, 101);
}
.emailenquiry
{
    font-size: 1.5rem;
   
}
.callenquiry
{
    font-size: 1.4rem;

}
@media screen and (max-width:480px)
{
    .enquiry_form
    {
        margin-top: 10%;
        /* margin-left: -4%; */
    }
    .enquiry_form input
    {
        height: 4%;
        font-size: 3vw;
        margin-bottom: -7%;
    }
    .message
    {
        font-size: 4vw;
        margin-bottom: 9%;
    }
    .button
    {
        height: 4%;
        font-size: 2.3vh;
    }
    .heading
    {
        margin-left: 4vw;
        margin-bottom: -12%;
    }
    /* box */
    .enquirybox
    {
        width: 100%;
        margin-left: 2%;
        height: 40%;
        padding-top: 10%;
        margin-top: 5%;
    }
    .contactheading
    {
        font-size: 1.6rem;
    }
    .emailenquiry
    {
        font-size: 1rem;
    }
    .callenquiry
    {
        font-size: 1.1rem;
    }
}

@media screen and (min-width:481px) and (max-width:767px)
{
    .enquiry_form
    {
        margin-top: 5%;
        margin-left: 5%;
        width: 90%;
        height: 130vw;
    }
    .heading
    {
        margin-left: -1%;
        font-size: 2rem;
        margin-bottom: -5%;
    }
    .enquiry_form input
    {
        height: 4.5%;
        width: 70%;
        font-size: 1.2rem;
        margin-left: 15%;
        margin-bottom: -7%;
    }
    .message
    {
        font-size: 1.2rem;
        margin-bottom: 9%;
        margin-left: 15%;
        width: 70%;
    }
    .button
    {
        height: 5%;
        font-size: 1.6rem;
    }
    /* box */
    .enquirybox
    {
        width: 100%;
        margin-left: 5%;
        height: 40%;
        padding-top: 5%;
        margin-top: 10%;
    }
    .contactheading
    {
        font-size: 3rem;
    }
    .emailenquiry
    {
        font-size: 1.5rem;
    }
    .callenquiry
    {
        font-size: 1.4rem;
    }
}
@media screen and (min-width:768px) and (max-width:991px)
{
    .enquiry_form
    {
        width: 106%;
        margin-left: -5%;
        height: 84vw;
    }
    .heading
    {
        margin-left: -2%;
        font-size: 2.3vw;
        margin-bottom: 5%;
    }
    .enquiry_form input
    {
        height: 5%;
        font-size: 1rem;
        margin-top: -15%;
        width: 85%;
        margin-left: 8%;
    }
    .message
    {
        font-size: 1rem;
        margin-bottom: 9%;
        margin-top: -15%;
        width: 85%;
        margin-left: 8%;
    }
    .button
    {
        height: 5%;
        font-size: 2vw;
    }
     /* box */
     .enquirybox
     {
         width: 120%;
         margin-left: -35%;
         height: 40%;
         padding-top: 10%;
         margin-top: 40%;
     }
     .contactheading
     {
         font-size: 1.8rem;
     }
     .emailenquiry
     {
         font-size: 1.1rem;
     }
     .callenquiry
     {
         font-size: 1rem;
     }
}
@media(min-width:992px) and (max-width:1200px)
{
    .enquiry_form
    {
        width: 120%;
        margin-left: -20%;
        height: 84vw;
    }
    .heading
    {
        margin-left: -2%;
        font-size: 3vw;
        margin-bottom: 5%;
    }
    .enquiry_form input
    {
        height: 5%;
        font-size: 1.3rem;
        margin-top: -10%;
        width: 85%;
        margin-left: 8%;
    }
    .message
    {
        font-size: 1.3rem;
        margin-bottom: 9%;
        margin-top: -10%;
        width: 85%;
        margin-left: 8%;
    }
    .button
    {
        height: 5%;
        font-size: 2.2vw;
    }
    /* box */
    .enquirybox
    {
        width: 140%;
        margin-left: -45%;
        height: 40%;
        padding-top: 10%;
        margin-top: 80%;
    }
    .contactheading
    {
        font-size: 2.8rem;
    }
    .emailenquiry
    {
        font-size: 1.4rem;
    }
    .callenquiry
    {
        font-size: 1.3rem;
    }
}