
.main
{
    padding-top: 3%;
    background-color: black;
}

.first_row h5
{
    font-size: 2.3rem;
    margin-bottom: 3%;
    padding-right: 5%;
    color: white;
    padding-bottom: 5%;
}
.first_row p{
    color: white;
    padding-right: 4%;
    font-size: 1.1rem;
}
.second_row h5
{
    font-size: 2.2rem;
    margin-bottom: 3%;
    padding-right: 10%;
    color: white;
    padding-bottom: 5%;
    
    
    
}
.second_row ul
{
    list-style: none;
    padding-right: 10%;
}
.second_row ul li
{
    color: white;
    font-size: 1.1rem;
    text-decoration: none;
    color: white;
    text-transform:uppercase;
    padding-right: 10%;
    padding-bottom: 3%;
    
}
.second_row ul li:hover{
    color: gray;
}
.second_row p{
    margin-top: 1%;
}
.email
{
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    
}
.list
{
    text-decoration: none;
}
.third_row h5
{
    font-size: 2rem;
    margin-bottom: 4%;
    padding-right: 5%;
    color: white;
    padding-bottom: 4%;
    margin-top: 1%;  
    
}
.reviewimg
{
    display: flex;
}
.tripadvisorimg
{
    height: 70%;
    width: 80%;
    margin-top: 5%;
    margin-right: 5%;
}
.googleimg
{
    height: 70%;
    width: 80%;
    margin-top: 5%;
    margin-right: 5%;
}


@media(max-width:480px)
{
   .main
   {
    padding-top: 7%;
    padding-left: 10%; 
    height: 39rem;
   }
   .footer .first_row
{
    margin-left: -7%;
}
.first_row p
{
    font-size: 0.7rem;
}
.footer .second_row
{
    margin-left: -3%;
    margin-top: 6%;
}
.second_row ul li
{
    font-size: 0.7rem;
    margin-top: 1%;
    margin-left: -5%;
}
.footer .third_row
{
    margin-left: -5%;
}
.first_row h5
{
    
    font-size: 1.2rem;
}
.second_row h5
{
    font-size: 1.3rem;

}

.second_row .emailcontent{
    margin-top: -2%;
    font-size: 0.8rem;
    margin-left: -2%;
}
.third_row h5
{
    font-size: 0.9rem;
    margin-top: 2%;
}

.tripadvisorimg
{
    height: 80%;
    width: 70%;
  margin-left: 20%;
}
.googleimg
{
    height: 80%;
    width: 70%;
    
}
}


@media (min-width:481px) and (max-width: 767px)
{
    .main
    {
        height: 39rem;    
    }
    .first_row h5
{
    
    font-size: 1.9rem;
    margin-bottom: -3%;
}
.first_row p
{
    font-size: 0.8rem;
}
.second_row h5
{
    font-size: 1.9rem;
    padding-top: 2%;
    margin-left: 5vw;
    margin-bottom: -2%;
}
.second_row ul li
{
    font-size: 0.8rem;
    margin-left: 8vw;
    
}
.third_row h5
{
    font-size: 1.3rem;
   
    
}
.second_row .emailcontent
{
    font-size: 0.9rem;
    margin-top: -1rem;
}
.tripadvisorimg
{
    height: 80%;
    width: 23vw;
    margin-top: -15%;
    margin-left: 100%;
    
}
.googleimg
{
    height: 80%;
    width: 23vw;
    margin-top: -15%;
    margin-left: 120%;
}
}

@media (min-width:768px) and (max-width:991px)
{
    .main
    {
     margin-top: 1%;
     height: 17rem;
    }
    
    .footer .first_row
    {
        margin-left: 2%;

    }   
    .first_row h5
    {
        font-size: 1.6rem;
        margin-bottom: 7%;
    }
    .first_row p
    {
        font-size: 0.8rem; 
    }
    .footer .second_row
{
    
    margin-left: -20%;
}
    .second_row h5
    {
        font-size: 1.6rem;
        margin-bottom: 5%;
    }
    .second_row p
    {
        font-size: 0.9rem; 
    }
    .second_row ul li
    {
        font-size: 0.8rem;
        margin-top: 2%;
        margin-left: -5%;
    }
    .footer .third_row
{
    margin-left: -10%;
}
.third_row h5
{
    font-size: 1.3rem;

}
.tripadvisorimg
{
    height: 80%;
    width: 85%;
    
}
.googleimg
{
    height: 80%;
    width: 85%;
    
}
}
@media (min-width:992px) and (max-width:1200px)
{
    
    .main
    {
     margin-top: 1%;
     height: 20rem;
    }
    
    .footer .first_row
    {
        margin-left: 2%;

    }   
    .first_row h5
    {
        font-size: 2rem;
        margin-bottom: 7%;
    }
    .first_row p
    {
        font-size: 1rem; 
    }
    .footer .second_row
{
    
    margin-left: -20%;
}
    .second_row h5
    {
        font-size: 2rem;
        margin-bottom: 5%;
    }
    .second_row p
    {
        font-size: 1rem; 
    }
    .second_row ul li
    {
        font-size: 1.1rem;
        margin-top: 2%;
        margin-left: -5%;
    }
    .footer .third_row
{
    margin-left: -10%;
}
.third_row h5
{
    font-size: 1.7rem;
   

}
.tripadvisorimg
{
    height: 90%;
    width: 80%;
   
}
.googleimg
{
    height: 90%;
    width: 80%;
    
}
}