.gujratpackageheading
{
    font-size:1.7rem; color: rgb(49, 47, 47); margin-top : 2%; padding-right:65% ; padding-left:1%;
}
.gujratlocation
{
    font-size:1.2rem; padding-right:52%;padding-left:1%;
}
.gujratitinerary
{
    padding-top:4%; padding-right:67%; font-size:1.6rem;font-weight:bold;
}
.gujratday1
{
    margin-Left:-63%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday2
{
    margin-Left:-72%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday3
{
    margin-Left:-75%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday4
{
    margin-Left:-75%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday5
{
    margin-Left:-75%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday6
{
    margin-Left:-75%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday7
{
    margin-Left:-76%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday8
{
    margin-Left:-60%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday9
{
    margin-Left:-67%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday10
{
    margin-Left:-72%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday11
{
    margin-Left:-70%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
.gujratday12
{
    margin-Left:-70%; font-weight:bold;margin-bottom:2%;  font-size:1.2rem;
}
@media (max-width:480px)
{
    .gujratpackageheading
    {
        font-size:1.3rem; color: rgb(49, 47, 47); margin-top : 1%; padding-right:15% ; padding-left:1%;
    }
    .gujratlocation
    {
        font-size:1rem; padding-right:40%;padding-left:1%;
    }
    .gujratitinerary
    {
        padding-top:4%; padding-right:43%; font-size:1.1rem;font-weight:bold;
    }
    .gujratday1
{
    margin-Left:-25%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday2
{
    margin-Left:-46%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday3
{
    margin-Left:-57%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday4
{
    margin-Left:-56%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday5
{
    margin-Left:-60%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday6
{
    margin-Left:-55%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday7
{
    margin-Left:-63%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday8
{
    margin-Left:-25%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday9
{
    margin-Left:-39%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday10
{
    margin-Left:-50%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday11
{
    margin-Left:-49%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
.gujratday12
{
    margin-Left:-49%; font-weight:bold;margin-bottom:2%;  font-size:1rem;
}
}
