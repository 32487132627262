.servicesmain
{
    margin-bottom: 12%;
}
.servicesimg
{
    height: 7vw;
    margin-top: 5%;
    margin-bottom: 3%;
}

.hotel
{
    height: 16vw;
    margin-top: 3vh;
    margin-bottom: 1vh;
}
.hotelcontent
{
    font-size: 1.7rem;
    text-transform: capitalize;
    font-weight: 600;
    color: red;
}
.car
{
    height: 25vw;
   margin-top: -2vw;
    margin-bottom: -11.5vh;
}
.carcontent
{
    font-size: 1.7rem;
    text-transform: capitalize;
    font-weight: 600;
    color: red;
}
.railway
{
    height: 19vw;
    margin-top: 0vw;
    margin-bottom: -3vh;
}
.railwaycontent
{
    font-size: 1.7rem;
    text-transform: capitalize;
    font-weight: 600;
    color: red;
}
.flight
{
    height: 17vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
}
.flightcontent{
    font-size: 1.7rem;
    text-transform: capitalize;
    font-weight: 600;
    color: red;
}
@media (max-width:480px) {
    .servicesmain
{
    margin-bottom: 40%; 
}
.servicesimg
{
    height: 15vw;
}

    .hotel
{
    height: 35vw;
    margin-top: 1vh;
    margin-bottom: 3%;
}
.hotelcontent
{
    font-size: 5vw; 
}
.car
{
    height: 47vw;
    margin-top: -2vh;
    margin-bottom: -13%;
}
.carcontent
{
    font-size: 5vw; 
    
}
.railway
{
    height: 39vw;
    margin-top: 3vh;
}
.railwaycontent
{
    font-size: 5vw; 
    
}
.flight
{
    height: 36vw;
    margin-top: 3vh;
    margin-bottom: 1%;
}
.flightcontent{
    font-size: 5vw; 
}
}
@media (min-width:481px) and (max-width:767px)
{
    .servicesmain
    {
        margin-top: -5%;
        margin-bottom: 10%; 
    }
    .servicesimg
    {
        height: 13vw;
    }
    
        .hotel
    {
        height: 25vw;
        margin-top: 1vh;
        margin-bottom: 2%;
    }
    .hotelcontent
    {
        font-size: 4vw; 
    }
    .car
    {
        height: 37vw;
        margin-top: -2vh;
        margin-bottom: -8%;
    }
    .carcontent
    {
        font-size: 4vw; 
        
    }
    .railway
    {
        height: 33vw;
        margin-left: 4%;
        margin-top: 1vh;
        margin-bottom: 0.5%;
    }
    .railwaycontent
    {
        font-size: 4vw; 
        margin-left: 6%;
        
    }
    .flight
    {
        height: 27vw;
        margin-top: 2vh;
        margin-left: 5%;
        margin-bottom: 1%;
    }
    .flightcontent{
        font-size: 4vw; 
        margin-left: 5%;
    }
    
}
@media (min-width:768px) and (max-width:991px)
{
    .servicesimg
    {
        height: 9vw;
    }
        .hotel
    {
        height: 17vw;
        margin-top: 1vh;
    }
    .hotelcontent
    {
        font-size: 1.3rem;
    }
    .flight
    {
        height: 19vw;
        margin-top: -1vh;
    }
    .flightcontent{
        font-size: 1.3rem;
       margin-top: -3%;
    }
    .car
    {
        height: 28vw;
    margin-top: -3vw;
    margin-bottom: -30%;
    }
    .carcontent
    {
        font-size: 1.4rem;
       margin-left: 20%;
    }
    .railway
    {
        height: 22vw;
        margin-top: -6%;
        margin-bottom: -2vh;
    }
    .railwaycontent
    {
        font-size: 1.3rem;
        
    }
}
@media (min-width:992px) and (max-width:1200px)
{
    .servicesimg
    {
        height: 9vw;
    }
    .car
    {
        
    margin-top: 1vw;
    margin-bottom: -25%;
    }
    .carcontent
    {
       margin-left: 20%;
    }
    .flight
    {
        height: 18vw;
        margin-top: 2vw;
    }
    .flightcontent{
       margin-top: -3%;
    }
    .railway
    {
        height: 20vw;
        margin-top: 10%;
        margin-bottom: -2vw;
    }
    .railwaycontent
    {
        font-size: 1.6rem;
        
    }
}
@media(min-width:1200px)
{
    .car
    {
    margin-bottom: -20%;
    }
    .carcontent
    {
       margin-left: 1%;
    }
    .railway
    {
        height: 20vw;
        margin-bottom: -1.2vw;
    }
    .railwaycontent
    {
        font-size: 1.6rem;
        
    }
}