
.packageheading
{
    height: 7vw;
    margin-bottom: 5%;
}
.moretourbtn
{
    margin-top:4vh; background-color:rgb(248, 219, 57); border: 0.2vw solid #ff8547; width:12vw ;color:blue ;font-size:3vh;
}
.driverhead
{
    
    padding-right: 5%;
    margin-top: -6%;
}
.rainbowflag
{
    height: 10%;
    margin-top: -2%;
    margin-left: -2%;
}
.companyintro
{
    font-size: 1.4rem;
font-weight:normal;
padding-left: 3%;
padding-right: 3%;
padding-top: 2%;

}
.rinkuhead
{
    color: #ff5805;
    font-weight: bold;
    margin-top: 5%;
}
.driverintro
{
font-size: 1.4rem;
font-weight:normal;
padding-left: 3%;
padding-right: 3%;
margin-top: 2%;
margin-bottom: -10%;
}
.thanking_card
{
    background-color: rgb(63, 207, 255);
    margin-bottom: -0.5%;
}
.thanking_img
{
    width: 15%;
    margin-left: -6%;
}
.thankingdiv
{
    margin-top: -2%;
    font-size: 1.3rem;
}
.thankingline
{
    font-size: 2.4rem;
    margin-top: 1%;
    font-weight: bold;
    padding-bottom: 2%;
}
    @media (max-width:480px)
    {
        
        .packageheading
        {
            height: 15vw;
            margin-top: 10%;  
        }
        .moretourbtn
        {
            width: 40vw;
            margin-bottom: 10%;
            font-size: 5vw;
        }
        .driverhead
        {
            font-size: 1.3rem;
            margin-top: 8%;
            font-weight: bold;
            margin-left: 5%;
        }
        .rainbowflag
        {
            height: 12vw;
           
        }
        .companyintro
        {
            font-size: 1rem;
        }
        .rinkuhead
        {
            font-size: 1.2rem;
            margin-top: 10%;
        }
        .driverintro
        {
            font-size: 1rem;
            margin-bottom: 5%;
        }
        .thanking_img
        {
            width: 35%;

        }
        .thankingdiv
        {
            margin-top: -3%;
            font-size: 1rem;
        }
        .thankingline
        {
            font-size: 1.2rem;
            margin-top: 2%;
            font-weight: bold;
        }
    }

    @media (min-width:481px) and (max-width:767px)
    {
        .packageheading
        {
            height: 10vw;
            margin-top: 4%;  
        }
        .d-block
        {
            height: 20rem;
        }
        .moretourbtn
        {
            width: 24vw;
            margin-bottom: 10%;
            margin-top: 5%;
            font-size: 3.5vw;
        }
        .driverhead
        {
            font-size: 1.5rem;
            margin-top: 5%;
            font-weight: bold;
            margin-left: 8%;
        }
        .rainbowflag
        {
            height: 7vw;
        }
        .companyintro
        {
            font-size: 1.1rem;
        }
        .rinkuhead
        {
            font-size: 1.2rem;
            margin-top: 5%;
        }
        .driverintro
        {
            font-size: 1.1rem;
            margin-bottom: 5%;
        }
         .thanking_card
         {
            margin-top: 5%;
         }
         .thanking_img
         {
             width: 25%;
             margin-left: 2%;
             margin-top: 2%;
 
         }
         .thankingdiv
         {
             margin-top: -3%;
             font-size: 1.2rem;
         }
         .thankingline
         {
             font-size: 1.5rem;
             margin-top: 2%;
             font-weight: bold;
             margin-bottom: 2%;
         }
    }
    @media (min-width:768px) and (max-width:991px)
    {
        .packageheading
        {
            height: 7vw;
            margin-bottom: 3%;
            margin-top: 3%;
        }
        
        .moretourbtn
        {
            margin-top:4vh; 
            background-color:rgb(248, 219, 57); 
            border: 0.2vw solid #ff8547; 
            width:20vw ;
            color:blue ;
            font-size:2.2vw;
        }
        .driverhead
        {
            font-size: 1.7rem;
            margin-top: 5%;
            font-weight: bold;
            margin-left: 8%;
        }
        .rainbowflag
        {
            height: 5vw;
            margin-top: 0.1%;
            margin-left: 1%;
        }
        .companyintro
        {
            font-size: 1.1rem;
        }
        .rinkuhead
        {
            font-size: 1.4rem;
            margin-top: 4%;
        }
        .driverintro
        {
            font-size: 1.1rem;
            margin-bottom: 5%;
        }
    }

    @media (min-width:992px) and (max-width:1200px)
    {
        .packageheading
        {
            height: 8vw;
            margin-bottom: 3%;
            margin-top: 3%;
        }
        .moretourbtn
        {
            margin-top:4%; 
            width:22% ;
            color:blue ;
            font-size:1.8rem;
        }
        .driverhead
        {
            font-size: 1.9rem;
            margin-top: 4%;
            font-weight: bold;
            margin-left: 8%;
        }
        .rainbowflag
        {
            height: 5vw;
            margin-top: 0.1%;
            margin-left: 2%;
        }
        .companyintro
        {
            font-size: 1.3rem;
        }
        .rinkuhead
        {
            font-size: 1.7rem;
            margin-top: 5%;
        }
        .driverintro
        {
            font-size: 1.3rem;
            margin-bottom: 5%;
        }
       
    }
    @media (min-width:1201px)
    {
        
        .moretourbtn
        {
            margin-top:1%; 
            width:20% ;
            color:blue ;
            font-size:1.7rem;
        }
        .driverhead
        {
            font-size: 1.9rem;
            margin-top: 4%;
            font-weight: bold;
            margin-left: 8%;
        }
        .rainbowflag
        {
            height: 5vw;
            margin-top: 0.1%;
            margin-left: 2%;
        }
        .companyintro
        {
            font-size: 1.3rem;
        }
        .rinkuhead
        {
            font-size: 1.7rem;
            margin-top: 5%;
        }
        .driverintro
        {
            font-size: 1.3rem;
            margin-bottom: 5%;
        }
    }